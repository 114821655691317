<template>
  <Top />
</template>

<script>
import Top from './components/Top.vue'

export default {
  name: 'App',
  components: {
    Top
  }
}
</script>

<style>
@import "./assets/css/base.css";
@import "./assets/css/fonts.css";
@import "./assets/css/_variables.css";

#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
}
</style>
