<template>
  <h1 class="logo">
    <img src="../../assets/images/logo.svg">
  </h1>
</template>

<script>
export default {
  name: 'Top'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h1 {
  margin: 0;
  padding: 0;
}

img {
  width: auto;
  height: 4.8rem;
  vertical-align: middle;
}
</style>
