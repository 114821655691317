<template>
  <div class="simple-button">
    <a :href="url">{{ text }}</a>
  </div>
</template>

<script>
export default {
  name: 'SimpleButton',
  props: {
    url: { 
      type: String,
      default: './'
    },
    text: {
      type: String,
      default: 'Button'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.simple-button {
  display: inline-block;
}

a {
  text-decoration: none;
  font-size: 1.6rem;
  line-height: 1;
  color: var(--my-gray);
}

a:visited {
  color: var(--my-gray);
}
</style>
