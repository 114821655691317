<template>
  <div class="top">
    <Logo />
    <p>I’m a digital product designer with 10+ years of experience, passionate about utilizing Interaction Design for our better future🔥 
      Formerly, I was a lead product designer at <a href="https://corp.raksul.com/en/" target="_blank" >Raksul</a> to design the Japanese TV ad platform, Novasell. 
      I work collaboratively, balance a strategic perspective and a human touch ✋</p>
    <ul>
      <li><SimpleButton url="https://www.linkedin.com/in/hilotaka/" text="Linkedin" /></li>
      <li><SimpleButton url="https://twitter.com/hilotacker" text="Twitter" /></li>
      <li><SimpleButton url="https://hilotacker.dribbble.com" text="Dribbble" /></li>
    </ul>
  </div>
</template>

<script>
import Logo from './Atoms/Logo'
import SimpleButton from './Atoms/SimpleButton'

export default {
  name: 'Top',
  components: {
    Logo,
    SimpleButton
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top {
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
  box-sizing: border-box;
}

@media screen and (min-width: 768px) {
  .top { padding: 0 14.4rem }
}

.logo {
  margin-top: 7.2rem;
}

@media screen and (min-width: 768px) {
  .logo { margin-top: 14.4rem }
}

p, ul {
  font-size: 1.6rem;
  margin-top: 4rem;
  width: 100%;
  line-height: 1.75;
  color: var(--my-black);
}

@media screen and (min-width: 768px) {
  p, ul { width: 75% }
}

@media screen and (min-width: 1024px) {
  p, ul { width: 50% }
}

ul {
  list-style: none;
  margin-top: 3.2rem;
  padding: 0;
  margin-bottom: 14.4rem;
}

li {
  margin: 0.8rem 0;
}

</style>
